@use 'global/colors';
@import '../../../scss/mixin/util.mixin';

:root {
	@include rgbfy('header-bg', linear-gradient(180deg, #{colors.get(red, 300)} 0, #{colors.get(red, 600)} 100%));
	@include rgbfy('header-height', 5rem);
	@include rgbfy('header-border-bottom', 0.1rem solid #{colors.get(red, 400)});
	@include rgbfy('account-selector--icon', #{colors.get(yellow, 500)});
    @include rgbfy('account-selector--icon-unselected', #{colors.get(red, 700)});
	@include rgbfy('account-settings--list-active-background', #{colors.get(red, 500)});
	@include rgbfy('account-settings--list-active-text', #{colors.get(neutral, 100)});
	@include rgbfy('account-settings--list-border', #{colors.get(red, 500)});
	@include rgbfy('account-settings--list-text', #{colors.get(pink, 600)});
	@include rgbfy('account-settings--list-text-hover', #{colors.get(neutral, 100)});
	@include rgbfy('account-settings--menu-background', #{colors.get(red, 600)});
	@include rgbfy('account-settings--note-text', #{colors.get(pink, 400)});
	@include rgbfy('account-settings--overlay-header', #{colors.get(red, 700)});
	@include rgbfy('account-settings--sensitive-data-confirmation-header-icon', transparent);
	@include rgbfy('account-settings--label', #{colors.get(neutral, 100)});
	@include rgbfy('account-settings--text', #{colors.get(pink, 500)});
	@include rgbfy('alertbox--alert-color', #{colors.get(neutral, 100)});
	@include rgbfy('background', linear-gradient(360deg, #{colors.get(red, 800)} 0.1%, #{colors.get(red, 800)} 0.11%, #{colors.get(red, 700)} 119.13%));
	@include rgbfy('box--border', #{colors.get(yellow, 500)});
	@include rgbfy('box--bottom-bg', #{colors.get(red, 700)});
	@include rgbfy('box--content-background', #{colors.get(red, 500)});
	@include rgbfy('box--header-background', #{colors.get(red, 600)});
	@include rgbfy('box--header-border', transparent);
	@include rgbfy('box--header-text', #{colors.get(neutral, 100)});
	@include rgbfy('box--info-bg', #{colors.get(red, 600)});
	@include rgbfy('box--main-bg', #{colors.get(red, 500)});
	@include rgbfy('box--status-type--icon', #{colors.get(pink, 500)});
	@include rgbfy('box--status-type--gender', #{colors.get(neutral, 100)});
	@include rgbfy('box--status-type--person', #{colors.get(pink, 600)});
	@include rgbfy('box--message--alert', #{colors.get(pink, 400)});
	@include rgbfy('box--message--default', #{colors.get(neutral, 400)});
	@include rgbfy('box--message--info', #55ACEE);
	@include rgbfy('box--message--success', #{colors.get(alert, success_500)});
	@include rgbfy('box--message--warning', #{colors.get(yellow, 400)});
	@include rgbfy('box--notify-bg', #{colors.get(red, 600)});
	@include rgbfy('box--round-border', #{colors.get(neutral, 100)});
	@include rgbfy('box--secondary--bg', #{colors.get(neutral, 850)});
	@include rgbfy('box--secondary--text', #{colors.get(neutral, 500)});
	@include rgbfy('box--separator-text', #{colors.get(pink, 600)});
	@include rgbfy('box--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('box--skip-bg', #{colors.get(red, 600)});
	@include rgbfy('button--primary--shadow', #{colors.get(yellow, 500)});
	@include rgbfy('button--primary--text', #{colors.get(red, 800)});
	@include rgbfy('captcha-background', #660000);
	@include rgbfy('card--default--background', #{colors.get(red, 800, 0.6)});
	@include rgbfy('card--default--background-darken', #{colors.get(red, 800)});
	@include rgbfy('card--default--number-active-shadow', #{colors.get(yellow, 500)});
	@include rgbfy('card--default--number-active-text', #{colors.get(yellow, 500)});
	@include rgbfy('card--default--number-background', #{colors.get(red, 800)});
	@include rgbfy('card--default--number-bg-darker', #660000);
	@include rgbfy('card--active--number-border', #{colors.get(red, 500)});
	@include rgbfy('card--default--number-border', #{colors.get(red, 500)});
	@include rgbfy('card--default--number-text', #{colors.get(pink, 600)});
	@include rgbfy('card--default--number-bg-white', #{colors.get(neutral, 100)});
	@include rgbfy('card--default--border-color', #{colors.get(neutral, 200)});
	@include rgbfy('card--default--link', #{colors.get(yellow, 500)});
	@include rgbfy('card--default--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('card--default--subtitle', #{colors.get(pink, 500)});
	@include rgbfy('card--default--title', #{colors.get(neutral, 100)});
	@include rgbfy('card--divider', #660000);
	@include rgbfy('category-selector--active-bg', #{colors.get(red, 600)});
	@include rgbfy('category-selector--border', #{colors.get(red, 600)});
	@include rgbfy('category-selector--border-hover', #{colors.get(yellow, 500)});
	@include rgbfy('category-selector--border-selected', #{colors.get(yellow, 500)});
	@include rgbfy('category-selector--text-active', #{colors.get(neutral, 100)});
	@include rgbfy('category-selector--text', #{colors.get(pink, 400)});
	@include rgbfy('category-selector--text-bold', #{colors.get(pink, 500)});
	@include rgbfy('celebrity--gradient-bg', #{colors.get(red, 800)});
	@include rgbfy('celebrity--gradient-bg-from', #{colors.get(red, 400)});
	@include rgbfy('celebrity--gradient-bg-to', #{colors.get(alert, progress_500)});
	@include rgbfy('celebrity--money-section-heading', #{colors.get(red, 400)});
	@include rgbfy('celebrity--money-section-text', #{colors.get(neutral, 600)});
	@include rgbfy('channel--album-item-counter-text', #{colors.get(pink, 500)});
	@include rgbfy('channel--album-total-counter', #{colors.get(neutral, 400)});
	@include rgbfy('channel--box--action-text', #{colors.get(pink, 500)});
	@include rgbfy('channel--box--bg-gr', #{colors.get(red, 600)});
	@include rgbfy('channel--box--control-text', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--hover-title-bg', #{colors.get(red, 600)});
	@include rgbfy('channel--box--icon', #{colors.get(pink, 500)});
	@include rgbfy('channel--box--icon-hover', #{colors.get(pink, 500)});
	@include rgbfy('channel--box--icon-active', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--icon-alert', #{colors.get(yellow, 500)});
	@include rgbfy('channel--box--icon-alert-rejected', #{colors.get(yellow, 500)});
	@include rgbfy('channel--box--inactive-mask', #{colors.get(red, 500)});
	@include rgbfy('channel--box--inner-bg', #{colors.get(red, 600)});
	@include rgbfy('channel--box--inner-bg-hover', #{colors.get(red, 700)});
	@include rgbfy('channel--box--inner-icon', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--inner-error-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--photo-upload-failed-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--inner-title-bg', #{colors.get(neutral, 200)});
	@include rgbfy('channel--box--inner-border', #{colors.get(red, 700)});
	@include rgbfy('channel--box--input-placeholder-text', #CA4646);
	@include rgbfy('channel--box--input-text', colors.get(pink, 400));
	@include rgbfy('channel--box--uploading-text', #555);
	@include rgbfy('channel--box--album-input-name', #{colors.get(neutral, 500)});
	@include rgbfy('channel--box--album-empty-bg', #{colors.get(neutral, 200)});
	@include rgbfy('channel--box--promo-ribbon-bg-from', #{colors.get(yellow, 400)});
	@include rgbfy('channel--box--promo-ribbon-bg-to', #DE9300);
	@include rgbfy('channel--box--promo-ribbon-text', #{colors.get(red, 700)});
	@include rgbfy('channel--box--rejected-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--box--text2', #{colors.get(pink, 600)});
	@include rgbfy('channel--box--page-switch-bg', #{colors.get(neutral, 150)});
	@include rgbfy('channel--box--page-switch-border', #{colors.get(neutral, 300)});
	@include rgbfy('channel--box--album-folder-gradient-bg', #{colors.get(neutral, 900)});
	@include rgbfy('channel--box--text-error-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--folder-bg-rgb', #{colors.get(neutral, 900)});
	@include rgbfy('channel--comment-bg', #{colors.get(red, 500)});
	@include rgbfy('channel--comment-border', #{colors.get(red, 700)});
	@include rgbfy('channel--comment-input-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--comment-remove-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--comment-border-color', #{colors.get(red, 700)});
	@include rgbfy('channel--comment-name', #{colors.get(neutral, 100)});
	@include rgbfy('channel--comment-text', #{colors.get(pink, 500)});
	@include rgbfy('channel--comment-title-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--comment-title-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--creation-progress-bar-gr', #{colors.get(yellow, 500)});
	@include rgbfy('channel--creation-progress-bar-gr-darken', #e6b800);
	@include rgbfy('channel--creation-progress-bar-gr-done', #{colors.get(alert, success_500)});
	@include rgbfy('channel--creation-progress-bar-gr-done-darken', #729a00);
	@include rgbfy('channel--creation-progress-bar-inner-shadow', #{colors.get(red, 800)});
	@include rgbfy('channel--creation-progress-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--creation-progress-bs', #{colors.get(neutral, 900, 0.25)});
	@include rgbfy('channel--creation-progress-icon-border-color', #{colors.get(neutral, 900)});
	@include rgbfy('channel--creation-progress-success-span-bg', #{colors.get(neutral, 100)});
	@include rgbfy('channel--creation-progress-text', #660000);
	@include rgbfy('channel--disabled--checkbox-shadow', #{colors.get(red, 400)});
	@include rgbfy('channel--disabled--inner-border', #{colors.get(red, 700)});
	@include rgbfy('channel--disabled--notes-inner-bg', #{colors.get(red, 600)});
	@include rgbfy('channel--dropdown--bg', #{colors.get(neutral, 900)});
	@include rgbfy('channel--dropdown--bg-hover', #{colors.get(neutral, 850)});
	@include rgbfy('channel--dropdown--link', #{colors.get(neutral, 500)});
	@include rgbfy('channel--dropdown--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('channel--editor-header', #{colors.get(red, 500)});
	@include rgbfy('channel--flip-card-back-text', #{colors.get(pink, 500)});
	@include rgbfy('channel--flip-card-disabled-text', #{colors.get(pink, 600)});
	@include rgbfy('channel--folder-bg', #{colors.get(red, 800)});
	@include rgbfy('channel--folder-overlay-text', #{colors.get(neutral, 100)});
	@include rgbfy('channel--gallery-info-text-shadow', #{colors.get(neutral, 900, 0.4)});
	@include rgbfy('channel--gallery-overlay-tools', #{colors.get(neutral, 100)});
	@include rgbfy('channel--info-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--info-number', #{colors.get(yellow, 500)});
	@include rgbfy('channel--info-text', #{colors.get(pink, 400)});
	@include rgbfy('channel--info-title', #{colors.get(neutral, 100)});
	@include rgbfy('channel--interface-button-hover', #{colors.get(red, 800)});
	@include rgbfy('channel--landing-list', #{colors.get(pink, 500)});
	@include rgbfy('channel--landing-title', #{colors.get(pink, 500)});
	@include rgbfy('channel--listpage-title', #{colors.get(neutral, 100)});
	@include rgbfy('channel--listpage-text', #{colors.get(pink, 500)});
	@include rgbfy('channel--media-bar-overlay-bg',  #{colors.get(neutral, 900, 0.6)});
	@include rgbfy('channel--message-bg', #{colors.get(red, 500)});
	@include rgbfy('channel--note--overlay-bg', #{colors.get(red, 800)});
	@include rgbfy('channel--note-info-text', #CA4646);
	@include rgbfy('channel--note-overlay-content-bg', #{colors.get(neutral, 100)});
	@include rgbfy('channel--note-overlay-text', #{colors.get(red, 800)});
	@include rgbfy('channel--overlay-bg', colors.get(red, 900));
	@include rgbfy('channel--overlay-text', #{colors.get(neutral, 100)});
	@include rgbfy('channel--playlist--bg', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--checkbox-label', #{colors.get(red, 800)});
	@include rgbfy('channel--playlist--checkbox-shadow', #{colors.get(red, 700)});
	@include rgbfy('channel--playlist--duration', #{colors.get(neutral, 400)});
	@include rgbfy('channel--playlist--fade', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--frame', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--frame-shadow', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--helper-bg', #{colors.get(red, 500)});
	@include rgbfy('channel--playlist--inner-gr', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--inner-gr-lighten', #{colors.get(neutral, 800)});
	@include rgbfy('channel--playlist--pager-gr', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--scrollbar-bg', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--scrollbar-bg-darken', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--scrollbar-gr-from', #{colors.get(neutral, 400)});
	@include rgbfy('channel--playlist--scrollbar-gr-to', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--shadow', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--time', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--top-msg-bg', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--top-msg-text', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--track-bar', #{colors.get(red, 800)});
	@include rgbfy('channel--playlist--track-bar-bg', #{colors.get(red, 800)});
	@include rgbfy('channel--playlist-close', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist-pager', #{colors.get(neutral, 500)});
	@include rgbfy('channel--price', #{colors.get(yellow, 500)});
	@include rgbfy('channel--price-bg', #{colors.get(neutral, 900)});
	@include rgbfy('channel--price-settings-mask', #{colors.get(red, 800)});
	@include rgbfy('channel--price-settings-scroll-container-bg', #{colors.get(red, 800)});
	@include rgbfy('channel--price-settings-tooltip-bg', #{colors.get(neutral, 900, 0.5)});
	@include rgbfy('channel--title-free-highlite', #{colors.get(pink, 500)});
	@include rgbfy('channel--title-paid-highlite', #{colors.get(yellow, 500)});
	@include rgbfy('channel-info--bg', #{colors.get(red, 600)});
	@include rgbfy('channel-info--intro--background-icon', #{colors.get(red, 600)});
	@include rgbfy('channel--video-converting-bg', #{colors.get(neutral, 200)});
	@include rgbfy('channel--video-remaining-time', #{colors.get(neutral, 100)});
	@include rgbfy('channel--video-control-icon', #{colors.get(neutral, 100)});
	@include rgbfy('channel--video-progess-bar', #{colors.get(decorative, silver_400, 0.8)});
	@include rgbfy('channel--creation-progress-success-bg-1', #7BD4C4);
	@include rgbfy('channel--creation-progress-success-bg-2', #687BC4);
	@include rgbfy('common--alert', #{colors.get(yellow, 500)});
	@include rgbfy('common--disabled', #{colors.get(neutral, 400)});
	@include rgbfy('common--error', #{colors.get(red, 400)});
	@include rgbfy('common--secondary', #{colors.get(pink, 500)});
	@include rgbfy('common--success', #{colors.get(alert, success_500)});
	@include rgbfy('common--warning', #{colors.get(yellow, 500)});
	@include rgbfy('common-tabs--container-bg', #{colors.get(red, 700)});
	@include rgbfy('common-tabs--link', #{colors.get(pink, 500)});
	@include rgbfy('common-tabs--link-active', #{colors.get(pink, 500)});
	@include rgbfy('common-tabs--link-active-secondary', #{colors.get(neutral, 100)});
	@include rgbfy('common-tabs--link-active-bg', #{colors.get(red, 500)});
	@include rgbfy('common-tabs--link-active-border', #{colors.get(yellow, 500)});
	@include rgbfy('common-tabs--link-bg', #{colors.get(red, 600)});
	@include rgbfy('common-tabs--link-border', #{colors.get(red, 700)});
	@include rgbfy('common-tabs--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('common-tabs--link-spacer', #{colors.get(red, 600)});
	@include rgbfy('company-reg--upload-result-bg', #{colors.get(red, 700)});
	@include rgbfy('company-reg--upload-result-shadow', #{colors.get(red, 500)});
	@include rgbfy('content-background', #{colors.get(red, 500)});
	@include rgbfy('content-header-border', #{colors.get(red, 800)});
	@include rgbfy('contest--icon-play', #{colors.get(neutral, 100)});
	@include rgbfy('contest--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('contest--tab-background', #{colors.get(red, 700)});
	@include rgbfy('crosslogin--header', #{colors.get(neutral, 100)});
	@include rgbfy('crosslogin--text', #{colors.get(pink, 500)});
	@include rgbfy('crosslogin--text-strong', #{colors.get(neutral, 100)});
	@include rgbfy('date-filter--bg', #{colors.get(red, 700)});
	@include rgbfy('date-filter--text', #660000);
	@include rgbfy('display-name-overlay--text', #{colors.get(pink, 400)});
	@include rgbfy('display-name-overlay--text-strong', #{colors.get(neutral, 100)});
	@include rgbfy('display-name-overlay--caption', #{colors.get(pink, 500)});
	@include rgbfy('download-guide--bg', #{colors.get(red, 500)});
	@include rgbfy('download-guide--close', #{colors.get(pink, 500)});
	@include rgbfy('download-guide--dot', #{colors.get(neutral, 400)});
	@include rgbfy('download-guide--header-bg', #{colors.get(red, 600)});
	@include rgbfy('download-guide--header-text', #{colors.get(pink, 600)});
	@include rgbfy('download-guide--text', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--exclusive-model--conditions-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--exclusive-model--rejected', #{colors.get(red, 300)});
	@include rgbfy('earn-more--exclusive-model--rejected-error', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--exclusive-model--title', #{colors.get(red, 700)});
	@include rgbfy('earn-more--exclusive-model--gradient-from', #{colors.get(red, 500)});
	@include rgbfy('earn-more--exclusive-model--gradient-to', #{colors.get(red, 600)});
	@include rgbfy('earn-more--jasmin-elite--progress-bar', #{colors.get(red, 800)});
	@include rgbfy('earn-more--jasmin-elite--section-bg', #{colors.get(red, 500)});
	@include rgbfy('earn-more--jasmin-elite--shadow', #660000);
	@include rgbfy('earn-more--king-of-the-room--section-bg', #{colors.get(red, 500)});
	@include rgbfy('earn-more--king-of-the-room--section-bg-gradient', #{colors.get(red, 700)});
	@include rgbfy('earn-more--king-of-the-room--title-gradient-from', #{colors.get(yellow, 500)});
	@include rgbfy('earn-more--king-of-the-room--title-gradient-to', #FD9402);
	@include rgbfy('earn-more--my-story--badge-bg', #{colors.get(red, 400)});
	@include rgbfy('earn-more--my-story--badge-border', #{colors.get(red, 700)});
	@include rgbfy('earn-more--my-story--more-info-bg', #{colors.get(red, 500)});
	@include rgbfy('earn-more--my-story--section-bg', #{colors.get(red, 700)});
	@include rgbfy('earn-more--my-story--section-bg-gradient', #{colors.get(red, 500)});
	@include rgbfy('earn-more--my-story--shadow', #660000);
	@include rgbfy('earn-more--private-show-discount--gradient-from', #{colors.get(red, 500)});
	@include rgbfy('earn-more--private-show-discount--gradient-to', #{colors.get(red, 700)});
	@include rgbfy('earn-more--section-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--badge-before-border', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--badge-before-gr-from', #{colors.get(red, 300)});
	@include rgbfy('earn-more--selection--badge-before-gr-to', #{colors.get(red, 500)});
	@include rgbfy('earn-more--selection--badge-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--badge-border', #CA4646);
	@include rgbfy('earn-more--selection--badge-text', #CA4646);
	@include rgbfy('earn-more--selection--countdown-bg', #{colors.get(red, 800)});
	@include rgbfy('earn-more--selection--countdown-text', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--selection--widget-completed-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--widget-completed-title-text', #CA4646);
	@include rgbfy('earn-more--selection--widget-li-bg', #{colors.get(red, 700)});
	@include rgbfy('earn-more--selection--widget-li-hover-bg', #660000);
	@include rgbfy('earn-more--snapshot--member-album-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--snapshot--member-album-border', #{colors.get(red, 700)});
	@include rgbfy('earn-more--snapshot--member-album-icon', #CA4646);
	@include rgbfy('earn-more--snapshot--member-album-small-text', #{colors.get(pink, 500)});
	@include rgbfy('earn-more--snapshot--member-album-user', #{colors.get(pink, 600)});
	@include rgbfy('earn-more--status-header-bg', #{colors.get(red, 700)});
	@include rgbfy('earn-more--text', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--text-gradient-start', #{colors.get(yellow, 400)});
	@include rgbfy('earn-more--text-gradient-stop', #F5A04B);
	@include rgbfy('earn-more--twowayaudio--block-gradient-start', #{colors.get(red, 700)});
	@include rgbfy('earn-more--twowayaudio--block-gradient-stop', transparent);
	@include rgbfy('earn-more--video-voice-call--badge-bg', #{colors.get(red, 400)});
	@include rgbfy('earn-more--video-voice-call--badge-border', #{colors.get(red, 700)});
	@include rgbfy('earn-more--video-voice-call--section-bg-gradient', #{colors.get(red, 700)});
	@include rgbfy('email-confirmation--icon', colors.get(pink, 600));
	@include rgbfy('email-confirmation--text', #{colors.get(pink, 500)});
	@include rgbfy('email-confirmation--title', #{colors.get(neutral, 100)});
	@include rgbfy('fanclub-gold', linear-gradient(225deg, #FFDC17, #FF9217));
	@include rgbfy('fanclub-silver', linear-gradient(225deg, #E7E7E7, #B5BDC0));
	@include rgbfy('fanclub-bronze', linear-gradient(225deg, #FFBFA2, #FD8840));
	@include rgbfy('first-login-steps-bg', #{colors.get(red, 700)});
	@include rgbfy('form--addon-bg', #{colors.get(neutral, 500)});
	@include rgbfy('form--addon-border', #{colors.get(neutral, 300)});
	@include rgbfy('form--addon-text', #{colors.get(neutral, 500)});
	@include rgbfy('form--alert-icon', #{colors.get(yellow, 500)});
	@include rgbfy('form--alert-icon-rejected', #{colors.get(red, 800)});
	@include rgbfy('form--dropdown--button-background', #{colors.get(red, 500)});
	@include rgbfy('form--dropdown--button-background-darken', #{colors.get(red, 700)});
	@include rgbfy('form--dropdown--button-caret', #{colors.get(pink, 600)});
	@include rgbfy('form--dropdown--item-hover-background', #{colors.get(red, 400)});
    @include rgbfy('form--dropdown--item-text', #{colors.get(neutral, 100)});
	@include rgbfy('form--dropdown--item-hover-text', #{colors.get(yellow, 500)});
	@include rgbfy('form--highlight', #{colors.get(red, 400)});
	@include rgbfy('form--info-icon', colors.get(pink, 500));
	@include rgbfy('form--info-icon-hover', colors.get(neutral, 100));
    @include rgbfy('form--ok-icon', colors.get(alert, success_500));
	@include rgbfy('form--input-caret', inherit);
	@include rgbfy('form--input-background', #{colors.get(red, 700)});
	@include rgbfy('form--input-background-lighten-15', #{colors.get(red, 400)});
	@include rgbfy('form--input-background-in-mediabox', transparent);
	@include rgbfy('form--input-border', #{colors.get(red, 500)});
	@include rgbfy('form--input-focus-background', #{colors.get(red, 700)});
	@include rgbfy('form--input-focus-border', #{colors.get(pink, 600)});
	@include rgbfy('form--input-focus-text', #{colors.get(neutral, 100)});
	@include rgbfy('form--input-loading', #{colors.get(pink, 500)});
	@include rgbfy('form--input-placeholder-icon', #CA4646);
	@include rgbfy('form--input-placeholder-text', #CA4646);
	@include rgbfy('form--input-state--error-border', #{colors.get(yellow, 500)});
	@include rgbfy('form--input-state--error-text', #{colors.get(yellow, 500)});
	@include rgbfy('form--input-text', #{colors.get(pink, 400)});
	@include rgbfy('form--input-shadow',  #{colors.get(red, 500)});
    @include rgbfy('form--input-eye-icon', colors.get(pink, 500));
    @include rgbfy('form--input-eye-icon-hover', colors.get(pink, 500));
	@include rgbfy('form--radio-background', transparent);
	@include rgbfy('form--radio-border', #{colors.get(pink, 600)});
	@include rgbfy('form--radio-check', #{colors.get(yellow, 500)});
	@include rgbfy('form--required-asterix', #{colors.get(yellow, 400)});
	@include rgbfy('form--selectorbox-border', #{colors.get(red, 600)});
	@include rgbfy('form--selectorbox-border-hover', #{colors.get(pink, 600)});
	@include rgbfy('form--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('form-text', #{colors.get(pink, 500)});
	@include rgbfy('form--toggle--handler-background', #{colors.get(red, 500)});
	@include rgbfy('form--toggle--on-background', #{colors.get(alert, success_500)});
	@include rgbfy('form--checkbox-border', #{colors.get(neutral, 500)});
	@include rgbfy('form--checkbox-border-disabled', #{colors.get(neutral, 500, .4)});
	@include rgbfy('form--checkbox-bg', #{colors.get(neutral, 100)});
	@include rgbfy('form--checkbox-check', #{colors.get(decorative, pink_400)});
	@include rgbfy('form-date--apply-bg', #{colors.get(red, 700)});
	@include rgbfy('form-date--apply-text', #{colors.get(yellow, 500)});
	@include rgbfy('form-date--dropdown-icon', #{colors.get(pink, 500)});
	@include rgbfy('form-date--range-selection', #{colors.get(alert, danger_500)});
	@include rgbfy('form-date--range-selection-text', #{colors.get(neutral, 100)});
	@include rgbfy('gallery--box-bg', #{colors.get(neutral, 900)});
	@include rgbfy('gallery--crop-tool', #FD9402);
	@include rgbfy('gallery--image-overlay-text', #{colors.get(neutral, 500)});
	@include rgbfy('gallery--loading-shadow', #{colors.get(red, 600)});
	@include rgbfy('gallery--profile-pic-bg', #660000);
	@include rgbfy('gallery--profile-pic-border', #{colors.get(red, 400)});
	@include rgbfy('gallery--profile-selector-bg', #{colors.get(red, 600)});
	@include rgbfy('gallery--selected', #{colors.get(yellow, 500)});
	@include rgbfy('gallery--settings-overlay-bg', #{colors.get(neutral, 900, 0.8)});
	@include rgbfy('gallery--settings-overlay-border', #{colors.get(neutral, 100, 0.8)});
	@include rgbfy('gallery--icon-photo-placeholder', colors.get(pink, 600));
	@include rgbfy('gallery--icon-check', colors.get(alert, success_500));
	@include rgbfy('gallery--icon-warning', colors.get(yellow, 500));
	@include rgbfy('gallery--icon-info', colors.get(pink, 500));
	@include rgbfy('gallery--icon-info-hover', colors.get(neutral, 100));
	@include rgbfy('gallery--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('global-progress-indicator--bg', #200000);
	@include rgbfy('global-progress-indicator--inner-bg', #{colors.get(red, 500)});
	@include rgbfy('guide--bottom-bg', #{colors.get(red, 500)});
	@include rgbfy('guide--bottom-border', #{colors.get(red, 600)});
	@include rgbfy('guide--separator-border-left', #{colors.get(red, 700)});
	@include rgbfy('guide--separator-border-right', #{colors.get(red, 800)});
	@include rgbfy('guide--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('h2-color', #{colors.get(neutral, 100)});
	@include rgbfy('hamburger', #{colors.get(neutral, 100)});
	@include rgbfy('header--border', #{colors.get(red, 500)});
	@include rgbfy('header--gr-from', #{colors.get(red, 400)});
	@include rgbfy('header--gr-from-darken', #{colors.get(red, 700)});
	@include rgbfy('header--gr-to', #660000);
	@include rgbfy('hr--light-background', #{colors.get(pink, 600)});
	@include rgbfy('hr-background', #{colors.get(red, 800)});
	@include rgbfy('hr-background-lighten-25', #{colors.get(red, 400)});
	@include rgbfy('huge-traffic-overlay--bold', #{colors.get(neutral, 100)});
	@include rgbfy('huge-traffic-overlay--text', #{colors.get(pink, 400)});
	@include rgbfy('huge-traffic-overlay--title', #{colors.get(yellow, 500)});
	@include rgbfy('icon--alert--yellow', colors.get(yellow, 500));
	@include rgbfy('icon--checkbox-bg', #{colors.get(alert, success_500)});
	@include rgbfy('icon--help--hover', #200000);
	@include rgbfy('icon--help--shadow', #{colors.get(red, 400)});
	@include rgbfy('icon--help--text', #{colors.get(red, 800)});
	@include rgbfy('icon--info--pink', #{colors.get(pink, 600)});
	@include rgbfy('icon--info--yellow', #DE9300);
	@include rgbfy('icon--ok--green', colors.get(alert, success_500));
	@include rgbfy('icon--shadow', #{colors.get(yellow, 500)});
	@include rgbfy('icon--tick--yellow', #{colors.get(yellow, 500)});
	@include rgbfy('icon--tick--turquise', #{colors.get(yellow, 500)});
	@include rgbfy('icon-in-circle--icon-gradient-end', #F5A04B);
	@include rgbfy('icon-in-circle--icon-gradient-start', #{colors.get(yellow, 400)});
	@include rgbfy('icon-in-circle--main', #{colors.get(yellow, 500)});
	@include rgbfy('id-uploader--border', #{colors.get(pink, 600)});
	@include rgbfy('id-uploader--dropzone-background', #{colors.get(red, 500)});
	@include rgbfy('id-uploader--dropzone-background-lighten', #{colors.get(red, 300)});
	@include rgbfy('id-uploader--icon', #{colors.get(yellow, 500)});
	@include rgbfy('id-uploader--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('members--empty-icon', #{colors.get(red, 700)});
	@include rgbfy('members--empty-text', #{colors.get(neutral, 100)});
	@include rgbfy('members--empty-profile-bg', #{colors.get(red, 700)});
	@include rgbfy('members--empty-profile-icon', #{colors.get(red, 600)});
	@include rgbfy('members--favorite', colors.get(pink, 500));
	@include rgbfy('members--favorite-active', colors.get(yellow, 500));
	@include rgbfy('members--disabled-member-name', #{colors.get(neutral, 100)});
	@include rgbfy('members--indicator--offline', #{colors.get(red, 800)});
	@include rgbfy('members--indicator--online', #12ff00);
	@include rgbfy('members--indicator--shadow-1', #{colors.get(red, 400)});
	@include rgbfy('members--indicator--shadow-2', #{colors.get(red, 800)});
	@include rgbfy('members--avatar', #{colors.get(neutral, 100)});
	@include rgbfy('members--avatar-bg', #{colors.get(red, 800)});
	@include rgbfy('members--fanclub-text', #{colors.get(pink, 400)});
	@include rgbfy('members--note-border', #{colors.get(red, 600)});
	@include rgbfy('members--note-delete', #{colors.get(red, 700)});
	@include rgbfy('members--note-delete-hover', #{colors.get(neutral, 100)});
	@include rgbfy('members--profile-picture-texts', #{colors.get(neutral, 100)});
	@include rgbfy('members--shadow', #200000);
	@include rgbfy('members--tab-bg', #{colors.get(red, 600)});
	@include rgbfy('members--tab-container-bg', #{colors.get(red, 700)});
	@include rgbfy('members--no-results-icon', #{colors.get(red, 600)});
	@include rgbfy('members--icon', #{colors.get(pink, 500)});
	@include rgbfy('members--icon-active', colors.get(neutral, 100));
	@include rgbfy('menu--account-exclusive', #{colors.get(neutral, 100)});
	@include rgbfy('menu--account-name', #{colors.get(neutral, 100)});
	@include rgbfy('menu--account-name-link', #{colors.get(yellow, 500)});
	@include rgbfy('menu--studio-account-type', #{colors.get(neutral, 100)});
	@include rgbfy('menu--account-type', #{colors.get(pink, 500)});
	@include rgbfy('menu--account-type-pending', #{colors.get(pink, 500)});
	@include rgbfy('menu--account-type-missing-data', #{colors.get(pink, 500)});
	@include rgbfy('menu--account-type-rejected', #{colors.get(red, 300)});
	@include rgbfy('menu--account-type-active', #84b401);
	@include rgbfy('menu--account-type-inactive', #{colors.get(pink, 600)});
	@include rgbfy('menu--account-type-suspended', #{colors.get(pink, 600)});
	@include rgbfy('menu--account-type-closed', #{colors.get(pink, 600)});
	@include rgbfy('menu--bg', #{colors.get(red, 900)});
	@include rgbfy('menu--active-bg', #{colors.get(red, 500)});
	@include rgbfy('menu--active-shadow', #{colors.get(red, 400)});
	@include rgbfy('menu--active-submenu-parent', #{colors.get(red, 800)});
	@include rgbfy('menu--active-text', #{colors.get(neutral, 100)});
	@include rgbfy('menu--arrow', #{colors.get(pink, 600)});
	@include rgbfy('menu--box-background', #{colors.get(neutral, 900, 0.2)});
	@include rgbfy('menu--double-image-border', #5C0000);
	@include rgbfy('menu--hover-bg', #{colors.get(red, 800)});
	@include rgbfy('menu--image-border', #8A4D4D);
	@include rgbfy('menu--mobile-background', #{colors.get(red, 800)});
	@include rgbfy('menu--mobile-cover-background', #{colors.get(red, 900)});
	@include rgbfy('menu--new-bg', #{colors.get(yellow, 500)});
	@include rgbfy('menu--new-text', #{colors.get(red, 800)});
	@include rgbfy('menu--notification--active--border', #{colors.get(red, 500)});
	@include rgbfy('menu--notification--counter', #8a4d4d);
	@include rgbfy('menu--notification--counter--active', #{colors.get(neutral, 100)});
	@include rgbfy('menu--notification--border', #{colors.get(red, 900)});
	@include rgbfy('menu--notification--hover--border', #{colors.get(red, 800)});
	@include rgbfy('menu--notification--hover--sub', #{colors.get(red, 800)});
	@include rgbfy('menu--profile-picture-bg', #{colors.get(red, 800)});
	@include rgbfy('menu--profile-picture-mobile-bg', #5C0000);
	@include rgbfy('menu--profile-picture-default-icon', #{colors.get(pink, 600)});
	@include rgbfy('menu--profile-picture-missing-icon', #{colors.get(pink, 600, 0.15)});
	@include rgbfy('menu--profile-picture-overlay', #{colors.get(red, 800)});
	@include rgbfy('menu--profile-picture-icon', #{colors.get(pink, 500)});
	@include rgbfy('menu--profile-picture-text-hover', #{colors.get(neutral, 100)});
	@include rgbfy('menu--profile-picture-upload', #{colors.get(yellow, 500)});
	@include rgbfy('menu--selected-bg', transparent);
	@include rgbfy('menu--separator', #{colors.get(pink, 600, 0.1)});
	@include rgbfy('menu--studio-image-background', #{colors.get(red, 800)});
	@include rgbfy('menu--sub-text', #{colors.get(pink, 600)});
	@include rgbfy('menu--text', #{colors.get(pink, 500)});
	@include rgbfy('messages--attachment-icon', #CA4646);
	@include rgbfy('messages--attachment-uploader-bg', #{colors.get(red, 700)});
	@include rgbfy('messages--empty-icon', colors.get(pink, 600));
    @include rgbfy('messages--empty-bg', colors.get(red, 600));
    @include rgbfy('messages--empty-description', colors.get(pink, 500));
	@include rgbfy('messages--list--active-bg', #{colors.get(red, 700)});
	@include rgbfy('messages--support--attachment-icon', #{colors.get(yellow, 500)});
	@include rgbfy('messenger--tab--active-border', #{colors.get(yellow, 500)});
	@include rgbfy('messenger--tab--border', #{colors.get(red, 600)});
	@include rgbfy('messenger--tab--active-text', #{colors.get(neutral, 100)});
	@include rgbfy('messenger--tab--inactive-text', #{colors.get(pink, 500)});
	@include rgbfy('migration--choose-box-bg', #{colors.get(red, 600)});
	@include rgbfy('migration--choose-box-mask', #{colors.get(red, 500)});
	@include rgbfy('migration--choose-box-mask-strong', #{colors.get(red, 800)});
	@include rgbfy('migration--finish-form-bg', #{colors.get(red, 600)});
	@include rgbfy('mixin--common-text-gr--from', #{colors.get(yellow, 500)});
	@include rgbfy('mixin--common-text-gr--to', #EDA300);
	@include rgbfy('mobile-crop-overlay--bold', #{colors.get(neutral, 100)});
	@include rgbfy('mobile-crop-overlay--text', #{colors.get(pink, 500)});
	@include rgbfy('mobile-crop-overlay-needed--text', #{colors.get(neutral, 500)});
	@include rgbfy('models--bad-rating-border', #{colors.get(alert, danger_500)});
	@include rgbfy('models--bad-rating-text', #{colors.get(alert, danger_500)});
	@include rgbfy('models--choose-existing-name', #{colors.get(pink, 500)});
	@include rgbfy('models--choose-existing-alert-text', #{colors.get(yellow, 500)});
	@include rgbfy('models--choose-existing-alert-icon', #{colors.get(yellow, 500)});
	@include rgbfy('models--choose-existing-selected-border', #{colors.get(yellow, 500)});
	@include rgbfy('models--current-level-sign-inner-bg', #00B259);
	@include rgbfy('models--current-level-sign-inner-text', #{colors.get(neutral, 100)});
	@include rgbfy('models--current-level-sign-inner-shadow', rgba(0, 111, 55, 0.7));
	@include rgbfy('models--current-level-sign-inner-text-shadow', #006F37);
	@include rgbfy('models--current-level-sign-outer-bg', #00B259);
	@include rgbfy('models--empty-text-header', #{colors.get(neutral, 100)});
	@include rgbfy('models--empty-figure', #{colors.get(red, 600)});
	@include rgbfy('models--filter-text', #{colors.get(pink, 500)});
	@include rgbfy('models--icon', #{colors.get(pink, 500)});
	@include rgbfy('models--icon-hover', #{colors.get(neutral, 100)});
	@include rgbfy('models--icon-active', #{colors.get(neutral, 100)});
  @include rgbfy('models--profile-add-icon', #{colors.get(pink, 600)});
	@include rgbfy('models--info-box-border', #{colors.get(red, 600)});
	@include rgbfy('models--level-sign-outer-shadow', #{colors.get(neutral, 900, 0.15)});
	@include rgbfy('models--manage-active-status', #{colors.get(alert, success_500)});
	@include rgbfy('models--manage-image-online-border', #00B259);
	@include rgbfy('models--next-level-bar-bg', #{colors.get(red, 700)});
	@include rgbfy('models--next-level-bar-line-bg', #00B259);
	@include rgbfy('models--next-level-bar-line-end-bg', #00B259);
	@include rgbfy('models--next-level-bar-line-start-bg', #00B259);
	@include rgbfy('models--next-level-bar-line-text', #006F37);
	@include rgbfy('models--next-level-bar-shadow', #{colors.get(red, 500)});
	@include rgbfy('models--next-level-border', #{colors.get(red, 600)});
	@include rgbfy('models--next-level-sign-inner-shadow', #{colors.get(neutral, 100, 0.3)});
	@include rgbfy('models--next-level-sign-inner-text-shadow', #{colors.get(neutral, 500)});
	@include rgbfy('models--next-level-sign-outer-bg', #{colors.get(neutral, 500)});
	@include rgbfy('models--no-rating-text', #{colors.get(neutral, 400)});
  @include rgbfy('models--choose-type-text', #{colors.get(pink, 500)});
	@include rgbfy('models--status-info-text', #{colors.get(neutral, 100)});
	@include rgbfy('models--total-payouts', #{colors.get(neutral, 100)});
	@include rgbfy('models--status-info-bg', #{colors.get(red, 700)});
	@include rgbfy('models--summarize-text', #{colors.get(yellow, 500)});
	@include rgbfy('models--quality-info-bg', #{colors.get(red, 600)});
	@include rgbfy('my-story--background', #{colors.get(neutral, 900)});
	@include rgbfy('my-story--dialog-background', #{colors.get(red, 600)});
	@include rgbfy('my-story--dialog-close', #{colors.get(pink, 600)});
	@include rgbfy('my-story--expire', #{colors.get(pink, 400)});
	@include rgbfy('my-story--main-close', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--menu', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--play', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--shade', #{colors.get(neutral, 900)});
	@include rgbfy('my-story--stripe-free', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--stripe-free-darken', #{colors.get(neutral, 600)});
	@include rgbfy('my-story--stripe-premium', #{colors.get(yellow, 500)});
	@include rgbfy('my-story--stripe-premium-darken', #dfb920);
	@include rgbfy('notification--background', #{colors.get(neutral, 900, 0.8)});
	@include rgbfy('notification--dark-background', #{colors.get(neutral, 900, 0.95)});
	@include rgbfy('notification--icon-silde-text', #{colors.get(red, 800)});
	@include rgbfy('notification--light-icon', #{colors.get(red, 300)});
	@include rgbfy('notification--text', #{colors.get(neutral, 100)});
	@include rgbfy('old-box--success-icon', #{colors.get(neutral, 100)});
	@include rgbfy('old-box--alert-bg', #{colors.get(neutral, 850)});
	@include rgbfy('old-box--alert-highlite-text', #{colors.get(neutral, 400)});
	@include rgbfy('old-box--alert-highlite-text-bg', #{colors.get(neutral, 900)});
	@include rgbfy('old-box--alert-icon', #{colors.get(neutral, 850)});
	@include rgbfy('old-box--alert-text', #{colors.get(neutral, 500)});
	@include rgbfy('old-box--calendar--bg', #{colors.get(red, 700)});
	@include rgbfy('old-box--calendar--border-active', #{colors.get(red, 500)});
	@include rgbfy('old-box--calendar--border-passive', #{colors.get(red, 700)});
	@include rgbfy('old-box--calendar--icon', #{colors.get(pink, 400)});
	@include rgbfy('old-box--calendar--text-passive', #{colors.get(red, 600)});
	@include rgbfy('old-box--error-bg', #{colors.get(red, 400)});
	@include rgbfy('old-box--error-icon', #{colors.get(red, 800)});
	@include rgbfy('old-box--error-text', #{colors.get(neutral, 500)});
	@include rgbfy('old-box--error-description', #{colors.get(neutral, 500)});
	@include rgbfy('old-box--info-bg-dark', #{colors.get(red, 800)});
	@include rgbfy('old-box--sticky-wrapper-shadow', #{colors.get(neutral, 850)});
	@include rgbfy('overlay-background', #{colors.get(red, 500)});
	@include rgbfy('overlay--close-button', #{colors.get(pink, 500)});
    @include rgbfy('overlay--close-button-hover', #{colors.get(pink, 500)});
	@include rgbfy('overlay--media-close-button', colors.get(neutral, 100));
	@include rgbfy('overlay--mobile-border', #{colors.get(pink, 500)});
	@include rgbfy('overlay-container-background', #200000);
	@include rgbfy('overlay-container-background-mobile', #{colors.get(red, 500)});
	@include rgbfy('overlay--text-shadow', #{colors.get(neutral, 900)});
	@include rgbfy('overlay--text-base', #555);
	@include rgbfy('overlay--header-h3', #{colors.get(neutral, 500)});
	@include rgbfy('overlay--header-h3-white', #{colors.get(neutral, 100)});
	@include rgbfy('overlay--arrow-hover', #{colors.get(neutral, 100)});
	@include rgbfy('overlay--with-header-close-btn', #{colors.get(neutral, 300)});
	@include rgbfy('overlay--with-header-close-btn-hover', #{colors.get(neutral, 800)});
	@include rgbfy('page-background', #{colors.get(red, 500)});
	@include rgbfy('payout-income--period-bg', #{colors.get(red, 500)});
	@include rgbfy('payout-options--box-bg', #{colors.get(red, 600)});
	@include rgbfy('payout-options--box-border', #{colors.get(red, 500)});
	@include rgbfy('payout-options--box-text', #{colors.get(neutral, 100)});
	@include rgbfy('payout-options--box-title', #{colors.get(neutral, 100)});
	@include rgbfy('payout-options--info-icon', #{colors.get(pink, 500)});
	@include rgbfy('payout-type--box-arrow', #{colors.get(yellow, 500)});
	@include rgbfy('payout-type--box-bg', transparent);
	@include rgbfy('payout-type--box-border', #{colors.get(red, 600)});
	@include rgbfy('payout-type--border', #{colors.get(red, 500)});
	@include rgbfy('payout-type--header-bg', #{colors.get(red, 700)});
	@include rgbfy('payout-type--header-text', #{colors.get(neutral, 100)});
	@include rgbfy('payout-info-header', #{colors.get(neutral, 100)});
	@include rgbfy('payout-info-detail-title', #{colors.get(pink, 400)});
	@include rgbfy('payout-info-detail-value', #{colors.get(pink, 500)});
	@include rgbfy('payout-info-info', #{colors.get(pink, 500)});
	@include rgbfy('payout-overview--strong', colors.get(neutral, 100));
	@include rgbfy('payout-overview--link', #{colors.get(yellow, 500)});
	@include rgbfy('payout-overview--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('payout-icon-info-hover', colors.get(neutral, 100));
	@include rgbfy('payout-icon-check', colors.get(alert, success_500));
	@include rgbfy('payout-overview-description', colors.get(pink, 500));
	@include rgbfy('photo-contest--thumbnail-empty-back', #{colors.get(red, 600)});
	@include rgbfy('policy--side-note', #{colors.get(pink, 500)});
	@include rgbfy('pornstar-info--earning', #{colors.get(yellow, 500)});
	@include rgbfy('pornstar-info--nth', #{colors.get(red, 700)});
	@include rgbfy('primary', #{colors.get(red, 500)});
	@include rgbfy('primary-darken-10', #{colors.get(red, 700)});
	@include rgbfy('privacy-rights--attention-bg', #{colors.get(red, 600)});
	@include rgbfy('privacy-rights--attention-text', #{colors.get(neutral, 100)});
	@include rgbfy('privacy-rights--warning', #{colors.get(yellow, 500)});
	@include rgbfy('privacy-rights--bold', #{colors.get(neutral, 100)});
	@include rgbfy('privacy-rights--text', #{colors.get(pink, 500)});
	@include rgbfy('privacy-rights--icon', #{colors.get(red, 700)});
	@include rgbfy('progress-bar--bg', #{colors.get(red, 800)});
	@include rgbfy('progress-bar--line', #{colors.get(yellow, 500)});
	@include rgbfy('progress-bar--stripe', #{colors.get(yellow, 400)});
	@include rgbfy('pro-membersip-badge-bg', #{colors.get(yellow, 500)});
	@include rgbfy('pro-membersip-badge-text', #{colors.get(red, 800)});
	@include rgbfy('promo-period-reset--overflowed', #{colors.get(red, 300)});
	@include rgbfy('promo-period-reset--used', #{colors.get(alert, success_500)});
	@include rgbfy('promo-period-reset--reached', #{colors.get(pink, 600)});
	@include rgbfy('promo-tools--box-bg', #{colors.get(red, 600)});
	@include rgbfy('promo-tools--box-border', #{colors.get(red, 700)});
	@include rgbfy('promo-tools--box-hover-bg', #{colors.get(red, 800)});
	@include rgbfy('promo-tools--tipslides-bg', #{colors.get(red, 400, 0.6)});
	@include rgbfy('promo-tools--date', #{colors.get(pink, 600)});
	@include rgbfy('promo-tools--grace-period-badge-bg', #{colors.get(neutral, 900, 0.8)});
	@include rgbfy('promo-tools--grace-period-badge-hover-bg', #{colors.get(neutral, 900)});
	@include rgbfy('promo-tools--grace-period-badge-text', #{colors.get(neutral, 400)});
	@include rgbfy('promo-tools--icon-active', colors.get(neutral, 100));
	@include rgbfy('promo-tools--info', colors.get(pink, 500));
	@include rgbfy('promo-tools--info-border', #{colors.get(red, 600)});
	@include rgbfy('promo-tools--info-bullet', #CA4646);
	@include rgbfy('promo-tools--info-icon', #{colors.get(pink, 600)});
	@include rgbfy('promo-tools--info-list-bg', #{colors.get(red, 700)});
	@include rgbfy('promo-tools--info-list-text', #{colors.get(pink, 400)});
	@include rgbfy('promo-tools--layer-bg', #{colors.get(red, 800)});
	@include rgbfy('promo-tools--play', #{colors.get(neutral, 100)});
	@include rgbfy('promo-tools--preview-bg', #{colors.get(red, 800)});
	@include rgbfy('promo-tools--preview-decoration', #{colors.get(red, 300)});
	@include rgbfy('promo-tools--preview-text', #{colors.get(neutral, 100)});
	@include rgbfy('promo-tools--preview-bg-inverse', #{colors.get(neutral, 100)});
	@include rgbfy('promo-tools--preview-text-inverse', #{colors.get(red, 700)});
	@include rgbfy('promo-tools--title', #{colors.get(pink, 600)});
	@include rgbfy('promo-tools--title-active', colors.get(neutral, 100));
	@include rgbfy('promo-video--help-heading-bg', #{colors.get(red, 500)});
	@include rgbfy('promo-video--help-section-gradient-from', #{colors.get(red, 600)});
	@include rgbfy('promo-video--help-section-gradient-to', #{colors.get(red, 700)});
	@include rgbfy('promo-video--list-container-bg', #{colors.get(red, 600)});
	@include rgbfy('promo-video--list-item', #{colors.get(pink, 400)});
	@include rgbfy('promo-video--mark-as-promo-border', #{colors.get(red, 600)});
	@include rgbfy('promo-video--mark-as-promo-footer', #{colors.get(pink, 500)});
	@include rgbfy('promo-video--more-info-link', #{colors.get(yellow, 500)});
	@include rgbfy('promo-video--step-description', #{colors.get(pink, 400)});
	@include rgbfy('promo-video--step-number', #{colors.get(yellow, 500)});
	@include rgbfy('promotion-banner--bg', #{colors.get(red, 500)});
	@include rgbfy('promotion-banner--qr', #{colors.get(neutral, 100)});
	@include rgbfy('promotion-tools--info-page--step-count', #{colors.get(yellow, 500)});
	@include rgbfy('promotion-tools--info-page--video-shadow', #{colors.get(neutral, 900, 0.9)});
	@include rgbfy('protip--bg', #{colors.get(neutral, 900)});
	@include rgbfy('protip--text', #{colors.get(neutral, 100)});
	@include rgbfy('protip--text-muted', #{colors.get(neutral, 400)});
	@include rgbfy('protip--link', #{colors.get(yellow, 500)});
	@include rgbfy('protip--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('referral--form-bg', #{colors.get(red, 600)});
	@include rgbfy('referral--link', #{colors.get(yellow, 500)});
	@include rgbfy('registration--step', #{colors.get(pink, 500)});
	@include rgbfy('registration--step-active', #{colors.get(red, 800)});
	@include rgbfy('registration--step-active-bg', #{colors.get(neutral, 100)});
	@include rgbfy('registration--step-active-border', #{colors.get(neutral, 100)});
	@include rgbfy('registration--step-border', #{colors.get(pink, 500)});
	@include rgbfy('registration--step-text', #{colors.get(pink, 500)});
	@include rgbfy('registration--step-text-bold', #{colors.get(neutral, 100)});
	@include rgbfy('ribbon--bg-end', #{colors.get(alert, progress_500)});
	@include rgbfy('ribbon--bg-start', #{colors.get(yellow, 500)});
	@include rgbfy('ribbon--icon', #{colors.get(red, 800)});
	@include rgbfy('ribbon--shadow-end', #{colors.get(yellow, 500)});
	@include rgbfy('ribbon--shadow-end-darken', #d6ab00);
	@include rgbfy('ribbon--shadow-inner', #{colors.get(neutral, 100)});
	@include rgbfy('ribbon--shadow-start', #{colors.get(neutral, 850)});
	@include rgbfy('ribbon--text', #{colors.get(neutral, 800)});
	@include rgbfy('ribbon--text-new', #{colors.get(red, 800)});
	@include rgbfy('scrollable--default--bar-bg', #{colors.get(red, 800)});
	@include rgbfy('scrollable--default--track-bg', transparent);
	@include rgbfy('scrollable--light--bar-bg', #{colors.get(pink, 600)});
	@include rgbfy('scrollable--light--bg', #{colors.get(red, 500)});
	@include rgbfy('scrollable--light--track-bg', #{colors.get(red, 700)});
	@include rgbfy('scrollable--light--track-lines', #{colors.get(red, 500)});
	@include rgbfy('scrollable--secondary--bar-bg', #{colors.get(neutral, 500)});
	@include rgbfy('scrollable--item-list-hover', #{colors.get(decorative, pink_400)});
	@include rgbfy('scrollpane--horizontal--bg', #{colors.get(pink, 600)});
	@include rgbfy('scrollpane--horizontal--border', #{colors.get(pink, 600)});
	@include rgbfy('scrollpane--scrollbar-bg', #{colors.get(neutral, 900, 0.3)});
	@include rgbfy('sidebar--background', #{colors.get(red, 800)});
	@include rgbfy('selection--background', #{colors.get(red, 700)});
	@include rgbfy('selection--text', #{colors.get(neutral, 100)});
	@include rgbfy('snapshot--action-icon', colors.get(neutral, 100));
	@include rgbfy('snapshot--camera-bg', colors.get(red, 900));
	@include rgbfy('snapshot--counter-text', #{colors.get(pink, 600)});
	@include rgbfy('snapshot--counter-text-active', #{colors.get(neutral, 100)});
	@include rgbfy('snapshot--ready-bg', #{colors.get(red, 600)});
	@include rgbfy('snapshot--warning-bg', #{colors.get(neutral, 900)});
	@include rgbfy('statistics-income--back-bg', #{colors.get(red, 700)});
	@include rgbfy('statistics-income--back-text', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--back-text-hover', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--border', #{colors.get(red, 600)});
	@include rgbfy('statistics-income--border-details', #{colors.get(red, 500)});
	@include rgbfy('statistics-income--current-earnings-bg', #{colors.get(red, 600)});
	@include rgbfy('statistics-income--current-earnings-text', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--current-earnings-value', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--level-achieved', #{colors.get(yellow, 500)});
	@include rgbfy('statistics-income--level-share', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--list-arrow', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--list-arrow-hover', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--list-bg', #{colors.get(red, 600)});
	@include rgbfy('statistics-income--list-container-bg', #{colors.get(red, 600)});
	@include rgbfy('statistics-income--list-item-bg', #{colors.get(red, 500)});
	@include rgbfy('statistics-income--list-data', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--list-date', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--list-details', #{colors.get(pink, 400)});
	@include rgbfy('statistics-income--list-header', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--list-icon', #{colors.get(yellow, 500)});
	@include rgbfy('statistics-income--list-payout-plus', #{colors.get(alert, success_500)});
	@include rgbfy('statistics-income--list-payout-minus', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--slider-dot', #{colors.get(yellow, 500)});
	@include rgbfy('statistics-income--slider-dot-border', #{colors.get(red, 500)});
	@include rgbfy('statistics-income--slider-dot-hover', #{colors.get(yellow, 400)});
	@include rgbfy('statistics-income--slider-line-active', #{colors.get(yellow, 500)});
	@include rgbfy('statistics-income--list-text', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--list-value', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--list-null-value', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--no-data', #{colors.get(pink, 500)});
	@include rgbfy('statistics-income--tooltip-background', #{colors.get(red, 900)});
	@include rgbfy('statistics-income--tooltip-font', #{colors.get(neutral, 100)});
	@include rgbfy('stepcrumb--background', transparent);
	@include rgbfy('stepcrumb--background-mobile', #{colors.get(red, 500)});
	@include rgbfy('stepcrumb--dash', #{colors.get(pink, 500)});
	@include rgbfy('stepcrumb--active-underline', #{colors.get(neutral, 100)});
	@include rgbfy('stepcrumb--modelreg--active', #{colors.get(neutral, 100)});
	@include rgbfy('support-chat--background', #{colors.get(red, 700)});
	@include rgbfy('support-chat--background-focus', #{colors.get(red, 700)});
	@include rgbfy('support-chat--button-bg-from', #{colors.get(yellow, 500)});
	@include rgbfy('support-chat--button-bg-to', #FD9402);
	@include rgbfy('support-chat--button-text', #{colors.get(red, 500)});
	@include rgbfy('support-chat--input-bg', #{colors.get(red, 800)});
	@include rgbfy('support-chat--input-border', #{colors.get(pink, 600)});
	@include rgbfy('support-chat--input-placeholder', #CA4646);
	@include rgbfy('support-chat--input-text', #{colors.get(neutral, 100)});
	@include rgbfy('support-chat--rating-bg', #{colors.get(neutral, 900)});
	@include rgbfy('support-chat--support', #a8c005);
	@include rgbfy('support-chat--system', #CA4646);
	@include rgbfy('support-chat--topic', colors.get(pink, 500));
	@include rgbfy('support-chat--topic-hover', colors.get(neutral, 100));
	@include rgbfy('support-chat--topic-bg', colors.get(red, 600, 0.6));
	@include rgbfy('support-chat--topic-border', colors.get(red, 700, 0.6));
	@include rgbfy('support-chat--user', #{colors.get(neutral, 100)});
	@include rgbfy('support-chat--welcome', #{colors.get(pink, 400)});
	@include rgbfy('support-chat--zoom-bg', #{colors.get(neutral, 900, 0.9)});
	@include rgbfy('support-chat--zoom-bg-hover', #{colors.get(neutral, 900)});
	@include rgbfy('support-chat--zoom-text', #{colors.get(neutral, 100)});
	@include rgbfy('switch-box--bg', transparent);
	@include rgbfy('switch-box--border', #{colors.get(red, 700)});
	@include rgbfy('switch-box--hover-bg', #{colors.get(red, 700)});
	@include rgbfy('switch-box--hover-text', #{colors.get(neutral, 100)});
	@include rgbfy('switch-box--shadow', #{colors.get(alert, danger_500)});
	@include rgbfy('table--dark-bg', #{colors.get(red, 600)});
	@include rgbfy('table--footer-bg', #{colors.get(red, 700)});
	@include rgbfy('table--header-bg', #{colors.get(red, 400)});
	@include rgbfy('table--header-text', #{colors.get(pink, 400)});
	@include rgbfy('table--highlight-bg', #{colors.get(red, 700)});
	@include rgbfy('table--regular-bg', #{colors.get(red, 600)});
	@include rgbfy('table--secondary-bg', #{colors.get(red, 400)});
	@include rgbfy('table--small-text', #{colors.get(pink, 500)});
	@include rgbfy('table--text', #{colors.get(pink, 600)});
	@include rgbfy('taglist--bg', #{colors.get(red, 600)});
	@include rgbfy('taglist--close', #{colors.get(pink, 500)});
	@include rgbfy('taglist--text', #{colors.get(neutral, 100)});
	@include rgbfy('text--approved', #{colors.get(alert, success_500)});
	@include rgbfy('text--base', #{colors.get(neutral, 100)});
	@include rgbfy('text--breadcrumb', #{colors.get(pink, 600)});
	@include rgbfy('text--breadcrumb-last', #{colors.get(neutral, 100)});
	@include rgbfy('text--emperor', #{colors.get(pink, 600)});
	@include rgbfy('text--link', #{colors.get(yellow, 500)});
	@include rgbfy('text--link-new', colors.get(yellow, 500));
	@include rgbfy('text--breadcrumb-last-item', #{colors.get(neutral, 100)});
	@include rgbfy('text--link-darken-5', #e6b800);
	@include rgbfy('text--link-darker', #EDA300);
	@include rgbfy('text--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('text--link-new-hover', colors.get(neutral, 100));
	@include rgbfy('text--paragraph', #{colors.get(pink, 400)});
	@include rgbfy('text--passive', #CA4646);
	@include rgbfy('text--rejected', #{colors.get(alert, danger_500)});
	@include rgbfy('text--secondary', #{colors.get(pink, 500)});
	@include rgbfy('text--secondary-darker', #{colors.get(pink, 600)});
	@include rgbfy('text--tertiary', #{colors.get(neutral, 400)});
	@include rgbfy('text--waiting', #{colors.get(neutral, 400)});
	@include rgbfy('toggle-on-off--bg', #{colors.get(red, 700)});
	@include rgbfy('toggle-on-off--knob-off-gr-end', #{colors.get(red, 600)});
	@include rgbfy('toggle-on-off--knob-off-gr-start', #{colors.get(red, 300)});
	@include rgbfy('toggle-on-off--knob-on-gr-end', #{colors.get(alert, success_500)});
	@include rgbfy('toggle-on-off--knob-on-gr-start', #{colors.get(alert, success_500)});
	@include rgbfy('toggle-on-off--off-text', #{colors.get(pink, 600)});
	@include rgbfy('toggle-on-off--on-text', #{colors.get(neutral, 100)});
	@include rgbfy('toggle-on-off--shadow', #{colors.get(red, 300)});
	@include rgbfy('toggle-on-off--switch-bg', #{colors.get(neutral, 900)});
	@include rgbfy('tooltip--bg', #{colors.get(neutral, 900, 0.9)});
	@include rgbfy('tooltip--text', #{colors.get(neutral, 300)});
	@include rgbfy('tutorial--bg--end', #{colors.get(red, 600)});
	@include rgbfy('tutorial--bg--start', #{colors.get(red, 700)});
	@include rgbfy('tutorial--label', #{colors.get(yellow, 500)});
	@include rgbfy('tutorial--note', #{colors.get(pink, 500)});
	@include rgbfy('tutorial--text', #{colors.get(pink, 400)});
	@include rgbfy('uploader5--progress-shadow', #CA4646);
	@include rgbfy('uploader5--progress-text', #{colors.get(red, 600)});
	@include rgbfy('uploader5--underlay', #{colors.get(neutral, 900)});
	@include rgbfy('vibratoy--border', #{colors.get(red, 600)});
	@include rgbfy('video-player--controls-bg', #{colors.get(neutral, 900, 0.7)});
	@include rgbfy('video-player--failure-bg', #{colors.get(neutral, 800)});
	@include rgbfy('video-player--failure-text', #{colors.get(neutral, 500)});
	@include rgbfy('video-player--fullscreen-bg', #{colors.get(neutral, 900)});
	@include rgbfy('video-player--pager', #{colors.get(neutral, 100)});
	@include rgbfy('video-player--slider-gr-from', #{colors.get(yellow, 400)});
	@include rgbfy('video-player--slider-gr-to', #EDA300);
	@include rgbfy('video-player--slider-shadow-from', #{colors.get(neutral, 100)});
	@include rgbfy('video-player--slider-shadow-to', #{colors.get(neutral, 900)});
	@include rgbfy('video-player--controls-play', #{colors.get(neutral, 300)});
	@include rgbfy('widget--daily-tip-note-input-text', #CA4646);
	@include rgbfy('widget--deduction-bg', #{colors.get(red, 800)});
	@include rgbfy('widget--exclusive-model--conditions-bg', #{colors.get(red, 600)});
	@include rgbfy('widget--exclusive-model--rejected', #{colors.get(red, 300)});
	@include rgbfy('widget--main-bg', #{colors.get(red, 700)});
	@include rgbfy('widget--news-header', #{colors.get(pink, 400)});
	@include rgbfy('widget--news-text', #{colors.get(pink, 600)});
	@include rgbfy('widget--promotion-block-bg', #{colors.get(red, 800)});
	@include rgbfy('widget--secondary-bg', #{colors.get(red, 600)});
	@include rgbfy('widget--secondary-bg-darken-3', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-badge-before-border', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-badge-before-gr-from', #{colors.get(red, 300)});
	@include rgbfy('widget--selection-badge-before-gr-to', #{colors.get(red, 500)});
	@include rgbfy('widget--selection-badge-bg', #{colors.get(red, 500)});
	@include rgbfy('widget--selection-badge-inactive-bg', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-badge-inactive-text', #CA4646);
	@include rgbfy('widget--selection-badge-text', #{colors.get(pink, 600)});
	@include rgbfy('widget--selection-deduction-bg', #{colors.get(red, 400)});
	@include rgbfy('widget--selection-deduction-text', #{colors.get(neutral, 100)});
	@include rgbfy('widget--selection-item-bg', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-item-bg-hover', #{colors.get(red, 500)});
	@include rgbfy('widget--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('widget--title', #{colors.get(neutral, 100)});
}
